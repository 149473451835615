<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <b-card>
        <b-card-body>
          <b-tabs>
            <b-tab
              active
              :title="`${$t('contact_requests.pending')} (${unapprovedRequests.length})`"
            >
              <b-table
                :fields="fields"
                :items="unapprovedRequests"
                responsive
                :per-page="perPage"
                :current-page="currentPageUnapproved"
              >
                <template #cell(approved)="data">
                  <b-badge
                    pill
                    variant="warning"
                  >
                    {{ data.value === true ? 'Yes' : 'No' }}
                  </b-badge>
                </template>
                <template #cell(number_of_employees)="data">
                  {{ data.item.responses.number_of_employees }}
                </template>
                <template #cell(lease_info)="data">
                  {{ data.item.responses.lease_info }}
                </template>
                <template #cell(created_at)="data">
                  {{ data.value | moment('LLL') }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="approveRequest(data.item.id)"
                  >
                    Approve for Registration
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2"
                    variant="danger"
                    @click="deleteRequest(data.item.id)"
                  >
                    Delete Contact Request
                  </b-button>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <p
                v-if="unapprovedRequests.length == 0"
                class="text-center"
              >
                No data to display
              </p>
              <b-col
                cols="12"
              >
                <b-pagination
                  v-if="unapprovedRequests.length > 0"
                  v-model="currentPageUnapproved"
                  :per-page="perPage"
                  :total-rows="unapprovedTotalRows"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-tab>
            <b-tab
              :title="`${$t('contact_requests.approved')} (${approvedRequests.length})`"
            >
              <b-table
                :fields="fields"
                :items="approvedRequests"
                responsive
                :per-page="perPage"
                :current-page="currentPageApproved"
              >
                <template #cell(approved)="data">
                  <b-badge
                    pill
                    variant="success"
                  >
                    {{ data.value === true ? 'Yes' : 'No' }}
                  </b-badge>
                </template>
                <template #cell(number_of_employees)="data">
                  {{ data.item.responses.number_of_employees }}
                </template>
                <template #cell(lease_info)="data">
                  {{ data.item.responses.lease_info }}
                </template>
                <template #cell(created_at)="data">
                  {{ data.value | moment('LLL') }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="approveRequest(data.item.id)"
                  >
                    Re-approve for Registration
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2"
                    variant="danger"
                    @click="deleteRequest(data.item.id)"
                  >
                    Delete Contact Request
                  </b-button>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <p
                v-if="approvedRequests.length == 0"
                class="text-center"
              >
                No data to display
              </p>
              <b-col
                cols="12"
              >
                <b-pagination
                  v-if="approvedRequests.length > 0"
                  v-model="currentPageApproved"
                  :per-page="perPage"
                  :total-rows="approvedTotalRows"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BBadge, BTabs, BTab, BCard, BCardBody, BCol, BRow, BTable, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BTabs,
    BTab,
    BCard,
    BCardBody,
    BTable,
    BButton,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'phone' },
        { key: 'office_name', label: 'Office Name' },
        { key: 'office_address', label: 'Office Address' },
        { key: 'number_of_employees', label: 'Number of Employees' },
        { key: 'lease_info', label: 'Lease or Owned' },
        { key: 'approved', label: 'Approved to Register?' },
        { key: 'created_at', label: 'Request Date' },
        { key: 'actions', label: 'Actions' },
      ],
      perPage: 5,
      currentPageApproved: 1,
      currentPageUnapproved: 1,
      unapprovedTotalRows: 1,
      approvedTotalRows: 1,
    }
  },
  computed: {
    ...mapGetters('contactRequests', ['contactRequests']),
    unapprovedRequests() {
      const requests = this.contactRequests.filter(r => r.approved === false)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.unapprovedTotalRows = requests.length
      return requests
    },
    approvedRequests() {
      const requests = this.contactRequests.filter(r => r.approved === true)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.approvedTotalRows = requests.length
      return requests
    },
  },
  async created() {
    try {
      await this.getContactRequests()
    } catch (e) {
      console.debug(`Could not load contact requests: ${e.message}`)
    }
  },
  mounted: {

  },
  methods: {
    ...mapActions('contactRequests', [
      'getContactRequests',
      'updateContactRequest',
      'deleteContactRequest',
    ]),
    approveRequest(crId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Approving a contact request will send out a registration email.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        this.loading = true
        try {
          if (result.value) {
            const requestObject = {
              id: crId,
              payload: {
                approved: true,
              },
            }
            await this.updateContactRequest(requestObject)
            this.$swal({
              icon: 'success',
              title: 'Approved!',
              text: 'The request was successfully approved.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        } catch (error) {
          console.debug(`Contact Request approval error: ${error.message}`)
          this.$swal({
            title: 'Error!',
            icon: 'danger',
            html: this.$t('errors.generic'),
            buttonsStyling: false,
          })
        }
      })
    },
    deleteRequest(crId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'This action cannot be reversed.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        this.loading = true
        try {
          if (result.value) {
            await this.deleteContactRequest(crId)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'The contact request was successfully deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        } catch (error) {
          console.debug(`Error in ContactRequests.vue: ${error.message}`)
          this.$swal({
            title: 'Error!',
            icon: 'error',
            html: this.$t('errors.generic'),
            buttonsStyling: true,
          })
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
